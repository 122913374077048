.mediaViewInfo {
  --web-view-name: Artboard – 1;
  --web-view-id: Artboard__1;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
:root {
  --web-view-ids: Artboard__1;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}
#Artboard__1 {
  position: absolute;
  width: 841.89px;
  height: 595.276px;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  --web-view-name: Artboard – 1;
  --web-view-id: Artboard__1;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
  z-index: 100;
}
#Path_5211 {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-dasharray: 10 6;
  stroke-dashoffset: 0;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5211 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 595.275px;
  left: 284.142px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_5212 {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-dasharray: 10 6;
  stroke-dashoffset: 0;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5212 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 595.275px;
  left: 552.772px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Image_125 {
  position: absolute;
  width: 215px;
  height: 47px;
  left: 47.512px;
  top: 26px;
  overflow: visible;
}
#Account_No_01540035529603 {
  left: 30.512px;
  top: 193px;
  position: absolute;
  overflow: visible;
  width: 127px;
  white-space: nowrap;
  line-height: 44px;
  margin-top: -17.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#Date_5102020 {
  left: 205.512px;
  top: 193px;
  position: absolute;
  overflow: visible;
  width: 66px;
  white-space: nowrap;
  line-height: 44px;
  margin-top: -17.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#Engineers_Name_PEC_Number_Cour {
  left: 30.512px;
  top: 210px;
  position: absolute;
  overflow: visible;
  width: 75px;
  white-space: nowrap;
  line-height: 20px;
  margin-top: -5.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#John_Doe_12345_Mechanical_Engi {
  left: 132.512px;
  top: 210px;
  position: absolute;
  overflow: visible;
  width: 97px;
  white-space: nowrap;
  line-height: 20px;
  margin-top: -5.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#Group_6739 {
  position: absolute;
  width: 240.53px;
  height: 43.156px;
  left: 30px;
  top: 305.744px;
  overflow: visible;
}
#Path_5217 {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5217 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 43.155px;
  left: 240px;
  top: 0.001px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_5215 {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5215 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 42.527px;
  left: 88px;
  top: 0.001px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Repeat_Grid_134 {
  position: absolute;
  width: 240.017px;
  height: 43px;
  left: 0.512px;
  top: 0px;
  overflow: hidden;
}
#Repeat_Grid_134_0 {
  position: absolute;
  width: 239.158px;
  height: 1px;
  left: 0px;
  top: 0.5px;
  overflow: visible;
}
#Path_5216 {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5216 {
  overflow: visible;
  position: absolute;
  width: 239.158px;
  height: 1px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Repeat_Grid_134_1 {
  position: absolute;
  width: 239.158px;
  height: 1px;
  left: 0px;
  top: 21.5px;
  overflow: visible;
}
#Path_5216_be {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5216_be {
  overflow: visible;
  position: absolute;
  width: 239.158px;
  height: 1px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Repeat_Grid_134_2 {
  position: absolute;
  width: 239.158px;
  height: 1px;
  left: 0px;
  top: 42.5px;
  overflow: visible;
}
#Path_5216_bg {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5216_bg {
  overflow: visible;
  position: absolute;
  width: 239.158px;
  height: 1px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_5218 {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5218 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 43.023px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Payment_Date {
  left: 8.512px;
  top: 6.256px;
  position: absolute;
  overflow: visible;
  width: 61px;
  white-space: nowrap;
  line-height: 20px;
  margin-top: -5.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#Course_Price {
  left: 8.512px;
  top: 26.256px;
  position: absolute;
  overflow: visible;
  width: 69px;
  white-space: nowrap;
  line-height: 20px;
  margin-top: -4.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  color: rgba(0, 0, 0, 1);
}
#PKR_6000- {
  left: 102.512px;
  top: 26.256px;
  position: absolute;
  overflow: visible;
  width: 58px;
  white-space: nowrap;
  line-height: 20px;
  margin-top: -4.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  color: rgba(0, 0, 0, 1);
}
#Rupees_in_words {
  left: 33.512px;
  top: 366px;
  position: absolute;
  overflow: visible;
  width: 73px;
  white-space: nowrap;
  line-height: 44px;
  margin-top: -17.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#Path_5219 {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5219 {
  overflow: visible;
  position: absolute;
  width: 163.584px;
  height: 1px;
  left: 106.927px;
  top: 376.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_5220 {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5220 {
  overflow: visible;
  position: absolute;
  width: 240.017px;
  height: 1px;
  left: 30.512px;
  top: 401.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Six_Thousand_Rupees_only {
  left: 117.512px;
  top: 363px;
  position: absolute;
  overflow: visible;
  width: 108px;
  white-space: nowrap;
  line-height: 20px;
  margin-top: -5.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#Image_125_bp {
  position: absolute;
  width: 215px;
  height: 47px;
  left: 309.512px;
  top: 29px;
  overflow: visible;
}
#Image_125_bq {
  position: absolute;
  width: 215px;
  height: 47px;
  left: 581.512px;
  top: 29px;
  overflow: visible;
}
#Errors_and_omissions_expected_ {
  left: 30.512px;
  top: 538px;
  position: absolute;
  overflow: visible;
  width: 223px;
  white-space: nowrap;
  line-height: 14px;
  margin-top: -2.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#Errors_and_omissions_expected__bs {
  left: 300.512px;
  top: 538px;
  position: absolute;
  overflow: visible;
  width: 223px;
  white-space: nowrap;
  line-height: 14px;
  margin-top: -2.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#Errors_and_omissions_expected__bt {
  left: 572.512px;
  top: 538px;
  position: absolute;
  overflow: visible;
  width: 223px;
  white-space: nowrap;
  line-height: 14px;
  margin-top: -2.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#Habib_Bank_Limited {
  left: 30.512px;
  top: 174px;
  position: absolute;
  overflow: visible;
  width: 104px;
  white-space: nowrap;
  line-height: 44px;
  margin-top: -16.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  color: rgba(0, 0, 0, 1);
}
#CPD {
  /* left: 677.512px; */
  left: 617.512px;
  top: 82px;
  position: absolute;
  overflow: visible;
  width: 33px;
  white-space: nowrap;
  line-height: 25px;
  margin-top: -5.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(1, 87, 6, 1);
}
#Group_6742 {
  position: absolute;
  width: 842px;
  height: 44px;
  left: -0.488px;
  top: 110px;
  overflow: visible;
}
#Group_67422 {
  position: absolute;
  width: 842px;
  height: 44px;
  left: -0.488px;
  top: 110px;
  overflow: visible;
}
#Rectangle_1002 {
  fill: rgba(107, 107, 107, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_1002 {
  position: absolute;
  overflow: visible;
  width: 842px;
  height: 27px;
  left: 0px;
  top: 17px;
}
#PECs_Copy {
  left: 128px;
  top: 27px;
  position: absolute;
  overflow: visible;
  width: 52px;
  white-space: nowrap;
  line-height: 44px;
  margin-top: -17.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  color: #0c0c0c;
}
#Engineers_Copy {
  left: 382px;
  top: 26px;
  position: absolute;
  overflow: visible;
  width: 72px;
  white-space: nowrap;
  line-height: 44px;
  margin-top: -17.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  color: #0c0c0c;
}
#Banks_Copy {
  left: 667px;
  top: 27px;
  position: absolute;
  overflow: visible;
  width: 55px;
  white-space: nowrap;
  line-height: 44px;
  margin-top: -17.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  color: #0c0c0c;
}
#Reference_No_01540035529603 {
  left: 84px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 135px;
  white-space: nowrap;
  line-height: 44px;
  margin-top: -17.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#Reference_No_01540035529603_b {
  left: 354px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 135px;
  white-space: nowrap;
  line-height: 44px;
  margin-top: -17.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#Reference_No_01540035529603_ca {
  left: 622px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 135px;
  white-space: nowrap;
  line-height: 44px;
  margin-top: -17.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#CPD_b {
  /* left: 404.512px; */
  left: 349.512px;
  top: 82px;
  position: absolute;
  overflow: visible;
  width: 33px;
  white-space: nowrap;
  line-height: 25px;
  margin-top: -5.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(1, 87, 6, 1);
}
#CPD_ca {
  /* left: 140.512px; */
  left: 80.512px;
  top: 82px;
  position: absolute;
  overflow: visible;
  width: 33px;
  white-space: nowrap;
  line-height: 25px;
  margin-top: -5.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(1, 87, 6, 1);
}
#Account_No_01540035529603_b {
  left: 297.512px;
  top: 193px;
  position: absolute;
  overflow: visible;
  width: 127px;
  white-space: nowrap;
  line-height: 44px;
  margin-top: -17.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#Engineers_Name_PEC_Number_Cour_b {
  left: 297.512px;
  top: 210px;
  position: absolute;
  overflow: visible;
  width: 75px;
  white-space: nowrap;
  line-height: 20px;
  margin-top: -5.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#John_Doe_12345_Mechanical_Engi_b {
  left: 399.512px;
  top: 210px;
  position: absolute;
  overflow: visible;
  width: 97px;
  white-space: nowrap;
  line-height: 20px;
  margin-top: -5.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#Group_6740 {
  position: absolute;
  width: 240.53px;
  height: 43.156px;
  left: 297px;
  top: 305.744px;
  overflow: visible;
}
#Path_5217_ca {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5217_ca {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 43.155px;
  left: 240px;
  top: 0.001px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_5215_cb {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5215_cb {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 42.527px;
  left: 88px;
  top: 0.001px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Repeat_Grid_134_cc {
  position: absolute;
  width: 240.017px;
  height: 43px;
  left: 0.512px;
  top: 0px;
  overflow: hidden;
}
#Repeat_Grid_134_cc_0 {
  position: absolute;
  width: 239.158px;
  height: 1px;
  left: 0px;
  top: 0.5px;
  overflow: visible;
}
#Path_5216_ce {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5216_ce {
  overflow: visible;
  position: absolute;
  width: 239.158px;
  height: 1px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Repeat_Grid_134_cc_1 {
  position: absolute;
  width: 239.158px;
  height: 1px;
  left: 0px;
  top: 21.5px;
  overflow: visible;
}
#Path_5216_cg {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5216_cg {
  overflow: visible;
  position: absolute;
  width: 239.158px;
  height: 1px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Repeat_Grid_134_cc_2 {
  position: absolute;
  width: 239.158px;
  height: 1px;
  left: 0px;
  top: 42.5px;
  overflow: visible;
}
#Path_5216_ci {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5216_ci {
  overflow: visible;
  position: absolute;
  width: 239.158px;
  height: 1px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_5218_cj {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5218_cj {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 43.023px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Payment_Date_ck {
  left: 8.512px;
  top: 6.256px;
  position: absolute;
  overflow: visible;
  width: 61px;
  white-space: nowrap;
  line-height: 20px;
  margin-top: -5.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#Course_Price_cl {
  left: 8.512px;
  top: 26.256px;
  position: absolute;
  overflow: visible;
  width: 69px;
  white-space: nowrap;
  line-height: 20px;
  margin-top: -4.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  color: rgba(0, 0, 0, 1);
}
#PKR_6000-_cm {
  left: 102.512px;
  top: 26.256px;
  position: absolute;
  overflow: visible;
  width: 58px;
  white-space: nowrap;
  line-height: 20px;
  margin-top: -4.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  color: rgba(0, 0, 0, 1);
}
#Rupees_in_words_cn {
  left: 300.512px;
  top: 366px;
  position: absolute;
  overflow: visible;
  width: 73px;
  white-space: nowrap;
  line-height: 44px;
  margin-top: -17.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#Path_5227 {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5227 {
  overflow: visible;
  position: absolute;
  width: 163.584px;
  height: 1px;
  left: 373.927px;
  top: 376.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_5228 {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5228 {
  overflow: visible;
  position: absolute;
  width: 240.017px;
  height: 1px;
  left: 297.512px;
  top: 401.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Six_Thousand_Rupees_only_cq {
  left: 384.512px;
  top: 363px;
  position: absolute;
  overflow: visible;
  width: 108px;
  white-space: nowrap;
  line-height: 20px;
  margin-top: -5.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#Habib_Bank_Limited_cr {
  left: 297.512px;
  top: 174px;
  position: absolute;
  overflow: visible;
  width: 104px;
  white-space: nowrap;
  line-height: 44px;
  margin-top: -16.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  color: rgba(0, 0, 0, 1);
}
#Account_No_01540035529603_cs {
  left: 568.512px;
  top: 193px;
  position: absolute;
  overflow: visible;
  width: 127px;
  white-space: nowrap;
  line-height: 44px;
  margin-top: -17.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#Engineers_Name_PEC_Number_Cour_ct {
  left: 568.512px;
  top: 210px;
  position: absolute;
  overflow: visible;
  width: 75px;
  white-space: nowrap;
  line-height: 20px;
  margin-top: -5.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#John_Doe_12345_Mechanical_Engi_cu {
  left: 670.512px;
  top: 210px;
  position: absolute;
  overflow: visible;
  width: 97px;
  white-space: nowrap;
  line-height: 20px;
  margin-top: -5.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#Group_6741 {
  position: absolute;
  width: 240.53px;
  height: 43.156px;
  left: 568px;
  top: 305.744px;
  overflow: visible;
}
#Path_5217_cw {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5217_cw {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 43.155px;
  left: 240px;
  top: 0.001px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_5215_cx {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5215_cx {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 42.527px;
  left: 88px;
  top: 0.001px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Repeat_Grid_134_cy {
  position: absolute;
  width: 240.017px;
  height: 43px;
  left: 0.512px;
  top: 0px;
  overflow: hidden;
}
#Repeat_Grid_134_cy_0 {
  position: absolute;
  width: 239.158px;
  height: 1px;
  left: 0px;
  top: 0.5px;
  overflow: visible;
}
#Path_5216_c {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5216_c {
  overflow: visible;
  position: absolute;
  width: 239.158px;
  height: 1px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Repeat_Grid_134_cy_1 {
  position: absolute;
  width: 239.158px;
  height: 1px;
  left: 0px;
  top: 21.5px;
  overflow: visible;
}
#Path_5216_da {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5216_da {
  overflow: visible;
  position: absolute;
  width: 239.158px;
  height: 1px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Repeat_Grid_134_cy_2 {
  position: absolute;
  width: 239.158px;
  height: 1px;
  left: 0px;
  top: 42.5px;
  overflow: visible;
}
#Path_5216_db {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5216_db {
  overflow: visible;
  position: absolute;
  width: 239.158px;
  height: 1px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_5218_c {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5218_c {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 43.023px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Payment_Date_c {
  left: 8.512px;
  top: 6.256px;
  position: absolute;
  overflow: visible;
  width: 61px;
  white-space: nowrap;
  line-height: 20px;
  margin-top: -5.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#Course_Price_c {
  left: 8.512px;
  top: 26.256px;
  position: absolute;
  overflow: visible;
  width: 69px;
  white-space: nowrap;
  line-height: 20px;
  margin-top: -4.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  color: rgba(0, 0, 0, 1);
}
#PKR_6000-_c {
  left: 102.512px;
  top: 26.256px;
  position: absolute;
  overflow: visible;
  width: 58px;
  white-space: nowrap;
  line-height: 20px;
  margin-top: -4.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  color: rgba(0, 0, 0, 1);
}
#Rupees_in_words_c {
  left: 571.512px;
  top: 366px;
  position: absolute;
  overflow: visible;
  width: 73px;
  white-space: nowrap;
  line-height: 44px;
  margin-top: -17.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#Path_5230 {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5230 {
  overflow: visible;
  position: absolute;
  width: 163.584px;
  height: 1px;
  left: 644.927px;
  top: 376.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_5231 {
  fill: transparent;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5231 {
  overflow: visible;
  position: absolute;
  width: 240.017px;
  height: 1px;
  left: 568.512px;
  top: 401.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Six_Thousand_Rupees_only_dc {
  left: 655.512px;
  top: 363px;
  position: absolute;
  overflow: visible;
  width: 108px;
  white-space: nowrap;
  line-height: 20px;
  margin-top: -5.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#Habib_Bank_Limited_dd {
  left: 568.512px;
  top: 174px;
  position: absolute;
  overflow: visible;
  width: 104px;
  white-space: nowrap;
  line-height: 44px;
  margin-top: -16.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  color: rgba(0, 0, 0, 1);
}
#Date_5102020_de {
  left: 472.512px;
  top: 193px;
  position: absolute;
  overflow: visible;
  width: 66px;
  white-space: nowrap;
  line-height: 44px;
  margin-top: -17.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
#Date_5102020_df {
  left: 743.512px;
  top: 193px;
  position: absolute;
  overflow: visible;
  width: 66px;
  white-space: nowrap;
  line-height: 44px;
  margin-top: -17.5px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
