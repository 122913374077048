/*   header*/

body {
  font-family: "Arial";
  color: rgb(70, 65, 65);
}
html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Balboa";
  src: url(../fonts/Balboa\ Bold.ttf);
}

#flipkart-navbar {
  background-color: rgb(191, 212, 198);
  color: #ffffff;
}
.row1 {
  padding-top: 10px;
  padding-right: 50px;
}

.row2 {
  padding-bottom: 20px;
}

.flipkart-navbar-input {
  padding: 11px 16px;
  border-radius: 2px 0 0 2px;
  border: 0 none;
  outline: 0 none;
  font-size: 15px;
}

.flipkart-navbar-button {
  background-color: #ffe11b;
  border: 1px solid #ffe11b;
  border-radius: 0 2px 2px 0;
  color: #565656;
  padding: 10px 0;
  height: 43px;
  cursor: pointer;
}

.cart-button {
  background-color: #2469d9;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23),
    inset 1px 1px 0 0 hsla(0, 0%, 100%, 0.2);
  padding: 10px 0;
  text-align: center;
  height: 41px;
  border-radius: 2px;
  font-weight: 500;
  width: 120px;
  display: inline-block;
  color: #ffffff;
  text-decoration: none;
  color: inherit;
  border: none;
  outline: none;
}

.cart-button:hover {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}

.cart-svg {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 8px;
}

.item-number {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.1);
  height: 20px;
  padding: 3px 6px;
  font-weight: 500;
  display: inline-block;
  color: #fff;
  line-height: 12px;
  margin-left: 10px;
}

.upper-links {
  display: inline-block;
  padding: 0 11px;
  line-height: 23px;
  /* font-family: 'Roboto', sans-serif; */
  letter-spacing: 0;
  color: inherit;
  border: none;
  outline: none;
  font-size: 12px;
}

.dropdown {
  position: relative;
  display: inline-block;
  margin-bottom: 0px;
}

.dropdown:hover .links {
  color: #000;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown .dropdown-menu {
  position: absolute;
  top: 100%;
  display: none;
  background-color: rgb(228, 233, 228) !important;
  color: black !important;
  left: 0px;
  border: 0;
  border-radius: 0;
  box-shadow: 0 4px 8px -3px #555454;
  margin: 0;
  padding: 0px;
}

.links {
  color: #fff;
  text-decoration: none;
}

.links:hover {
  color: #fff;
  text-decoration: none;
}

.profile-links {
  font-size: 12px;
  /* font-family: 'Roboto', sans-serif; */
  border-bottom: 1px solid #e9e9e9;
  box-sizing: border-box;
  display: block;
  padding: 0 11px;
  line-height: 23px;
}

.profile-li {
  padding-top: 2px;
}

.largenav {
  display: none;
}

.smallnav {
  display: block;
}

.smallsearch {
  margin-left: 15px;
  margin-top: 15px;
}

.menu {
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .largenav {
    display: block;
  }

  .smallnav {
    display: none;
  }

  .smallsearch {
    margin: 0px;
  }
}

/*Sidenav*/
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  box-shadow: 0 4px 8px -3px #555454;
  padding-top: 0px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  color: #fff;
}

@media screen and (max-height: 450px) {
  .sidenav a {
    font-size: 18px;
  }
}

.sidedropdown-itemheading {
  font-size: 36px;
  color: #fff;
}

/*footer*/
.col_white_amrc {
  color: #fff;
}

footer {
  width: 100%;
  background-color: #2c3449;

  min-height: 250px;
  /*    padding: 10px 0px 82px 0px;*/
}

.pt2 {
  padding-top: 69px;
  margin-bottom: 16px;
  font-size: 29px;
}

.hr_row {
  border-top: 3px solid #6c7180;
  margin-top: unset;
}

footer p {
  font-size: 13px;
  color: #ccc;
  padding-bottom: 0px;
  margin-bottom: 8px;
}

.mb10 {
  padding-bottom: 15px;
}

.footer_ul_amrc {
  margin: 0px;
  list-style-type: none;
  font-size: 14px;
  padding: 0px 0px 10px 0px;
}

.footer_ul_amrc li {
  padding: 0px 0px 5px 0px;
}

.footer_ul_amrc li a {
  color: white;
}

.footer_ul_amrc li a:hover {
  color: #fff;
  text-decoration: none;
}

.float_l {
  float: left;
}
.float_r {
  float: right;
}

.padding-right {
  padding-right: 10px;
}

.footer_ul2_amrc {
  margin: 0px;
  list-style-type: none;
  padding: 0px;
}

.footer_ul2_amrc li p {
  display: table;
}

.footer_ul2_amrc li a:hover {
  text-decoration: none;
}

.footer_ul2_amrc li i {
  margin-top: 5px;
}

.bottom_border {
  padding-bottom: 20px;
}

.foote_bottom_ul_amrc {
  list-style-type: none;
  padding: 0px;
  display: table;
  margin-top: 10px;
  margin-right: auto;
  margin-bottom: 10px;
  margin-left: auto;
}

.foote_bottom_ul_amrc li {
  display: inline;
}

.foote_bottom_ul_amrc li a {
  color: #999;
  margin: 0 12px;
}

.social_footer_ul {
  display: table;
  margin: 15px auto 0 auto;
  list-style-type: none;
}

.social_footer_ul li {
  padding-left: 20px;
  padding-top: 10px;
  float: left;
}

.social_footer_ul li a {
  color: #ccc;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 50%;
}

.social_footer_ul li i {
  width: 20px;
  height: 20px;
  text-align: center;
}

/*MY_self*/

.p_f13 {
  font-size: 13px;
  color: #838383;
}

.center {
  text-align: center;
  margin-bottom: 30px;
}

.col_padding {
  padding-right: unset;
  padding-left: unset;
}

.col_margin {
  margin-left: unset;
  margin-right: unset;
}

.ml-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.pl-18px {
  padding-left: 18px;
  font-weight: 500;
  color: black;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; */
}

.forget {
  color: green;
  /*            margin-left: 56px;*/
  font-weight: 500;
  font-size: 12px;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; */
}

.form-paddin-margin {
  border: 1px solid #efefef;
  padding: 25px 12% 22px 12%;
  margin-bottom: 50px;
  margin-top: 50px;
}

.pr_7 {
  padding-right: unset;
  padding-left: unset;
}

.pl_12 {
  padding-left: 12%;
}

.pl_26 {
  padding-left: 20%;
}
.pl_5 {
  padding-left: 5%;
}

.pl_20 {
  padding-left: 25px !important;
}

.mr_23 {
  margin-right: 23px;
}
.mtb_20 {
  margin-top: 17px;
  margin-bottom: 20px;
}

.check-pl {
  padding-left: 10%;
}

.Pt_10 {
  padding-top: 10px;
}

.Pt_4v {
  padding-top: 4vh;
}

.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex_colum {
  display: flex;
  flex-direction: column;
}
.flex_colum_center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex_row_reverse {
  display: flex;
  flex-flow: row-reverse;
}

.Pt_15 {
  padding-top: 15px;
}

.pt_10I {
  padding-top: 10px !important;
}

.mt_10 {
  margin-top: 10px;
}

.mt_20 {
  margin-top: 20px;
}

.form-control:focus {
  border-color: black;
  box-shadow: unset;
}

.w_90 {
  width: 90%;
  margin-left: 6%;
}
.pecxlogo {
  margin-left: 10px;
}
.masthead {
  height: 75vh;
  /*  min-height: 500px;*/
  /*  background-image: url('image/photo-1528731708534-816fe59f90cb.jpg');*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.box {
  padding: 50px 40px;
  background-color: #d8d8d8;
  border-radius: 10px;
  color: white;
  background-size: cover;
}
.box:hover {
  cursor: pointer;
  opacity: 0.9;

  transition: all 0.5s;
}
.mr_24 {
  margin-left: 12px;
  margin-right: 12px;
}
.bg_col {
  background-color: #2c3449;
}
.bg_col3 {
  background-image: url("../image/cpd/slider\ 3-01.jpg");
}

.bg_col4 {
  background-image: url("../image/cpd/slider\ 15-01.jpg");
}
.bg_col1 {
  background-image: url("../image/cpd/Slider\ 10-01.jpg");
}
.bg_col2 {
  background-image: url("../image/cpd/slider\ 13-01.jpg");
}
.bg_stick {
  background-image: url("../image/cpd/slider\ 5-01.png");
  background-size: cover;
}

.pd_80 {
  padding: 80px;
}

.co_white {
  color: white;
}
.co_white_imp {
  color: white !important;
}
.bordersilver {
  border: "1px solid #828793";
}

.co_green {
  color: #06580e;
}
.co_crimson {
  background-color: crimson !important;
}
.co_red {
  color: red;
}
.backco_green {
  background-color: #06580e !important;
}
.mr_3 {
  margin: 3rem;
}

.pd_right {
  padding-right: 12rem;
}

.card1 {
  width: 100%;
  height: 250px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.card2 {
  width: 75%;
  height: 26vh;
}

.card_w {
  width: 250px;
}

.fw_500 {
  font-weight: 500;
}

.pd_3 {
  padding: 3rem;
}

.img_w {
  width: 300px;
  height: 200px;
}

.pd_23px {
  padding: 0px 23px;
}

.sds {
  background-color: #2c3449;
  padding: unset;
  border: unset !important;
}
.fz_12 {
  font-size: 0.75rem;
}
.fz_2re {
  font-size: 2rem;
}
.fz_16p {
  font-size: 16px;
}
.files-list {
  margin-top: 20px;
  height: 300px;
  border-radius: 15px;
  padding: 5px;
  border: 1px solid rgb(92, 91, 91);
  overflow-y: scroll;
  overflow-x: hidden;
}
.fz_14 {
  font-size: 14px;
}

.fz_13 {
  font-size: 13px;
}

.fz_16 {
  font-size: 0.9rem;
}

.mr_36 {
  margin-bottom: 45px;
}

.vl {
  border-right: 3px solid green;
  height: 90px;
}

.wh_150 {
  width: 180px;
  height: 180px;
}

.hl {
  border-bottom: 2px solid #757a88;
  height: 0px;
  width: 76%;
  margin-left: auto;
  margin-right: auto;
}

.pt_20 {
  padding-top: 20px;
}
.news_heading {
  text-align: center;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: rgb(255, 255, 255);
  background-color: rgb(44, 52, 73);
}
.mr_5 {
  margin-right: 5%;
}

.mr_unset {
  margin-right: unset;
}

#none {
  display: none;
}

#none_hover {
  display: block;
}

.circle {
  padding: 32px;
  border: 1px solid #757a88;
  width: 100px;
  border-radius: 50%;
  height: 100px;
}

.bc_green {
  background-color: white;
  color: #06580e;
}

.bc_green:hover {
  background-color: #06580e;
  color: white;
}

.mlr-p-30 {
  padding-left: 30px;
  padding-right: 30px;
  /* color: #838383; */
}
.plr_flexcontainer {
  padding-left: 0px;
  padding-right: 0px;
}
.lb-font {
  color: #575757;
  font-size: 12px;
}

.form-paddin-register {
  border: 1px solid #efefef;
  padding: 25px 0px 22px 0px;
  margin-bottom: 40px;
  margin-top: 25px;
  /*margin-right: 4%;*/
  border-top: 5px solid #06580e;
}
.a_width {
  text-align: center;
  width: 100%;
}

/* input[type=text],
input[type=password],
input[type=email] {
    font-family: auto;
} */

.bold {
  font-weight: bolder;
}

.plr_2 {
  padding-left: 3px;
  padding-right: 3px;
}

.ml_6 {
  margin-left: 6%;
}

.ml_-30 {
  margin-left: -20px;
}

.ml_10 {
  margin-left: 10%;
}

.ml_10px {
  margin-left: 10px;
}
.ml_15p {
  margin-left: 20px;
}
.pl_0 {
  padding-left: 0px;
}

.pt_100 {
  padding-top: 65px;
}

.pt_10 {
  padding-top: 10vh;
}

.pt_5 {
  padding-top: 5vh;
}

.pb_5 {
  padding-bottom: 5vh;
}
.pb_5px {
  padding-bottom: 5px;
  padding-top: 7px;
}
.backco_D7FEBD {
  background-color: #d7febd;
}

.pb_13 {
  padding-bottom: 13px !important;
}

.pb_unset {
  padding-bottom: unset;
}

.pb_10 {
  padding-bottom: 10px !important;
}
.peb_pb_10 {
  padding-bottom: 10px !important;
}

.mb_5 {
  margin-bottom: 5vh;
}

.mb_10 {
  margin-bottom: 10px;
}

.mb_20 {
  margin-bottom: 20px;
}

.margin_2 {
  margin: 2vh 0px;
}

.w_112 {
  width: 112%;
}

.ff_auto {
  font-family: "Arial";
}

.pt_3 {
  padding-top: 3px;
}
.sub_new {
  padding: 35px 0px 8px;
}
.our_num {
  padding-top: 10vh;
}
.join {
  background-color: white !important;
  color: #6c7281;
}

.join:hover {
  background-color: #6c7281 !important;
  color: white;
}
.guideline {
  margin-top: 60px;
}
.into-p {
  line-height: 1.6;
  margin-top: 20px;
  font-size: 14px;
  text-align: justify;
  vertical-align: middle;
}
.chairman_p {
  color: #20a84c;
  background-color: #fdfdfd;
  /* padding: 0px 0px 4px 20px;*/
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
}
.chairman_p:active,
.chairman_p:focus {
  color: white !important;
  background-color: #20a84c;
}

.chairman_p:hover {
  color: white !important;
  background-color: #20a84c;
  padding: 10px;
}
.chairman_center {
  color: #20a84c;
  background-color: #fdfdfd;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 0px 10px 18%;
}
.chairman_center:hover {
  color: white !important;
  background-color: #20a84c;
  padding: 10px 0px 10px 18%;
}
.sub_menu_active {
  background: white;
  color: #06580e;
  border-left: 5px solid #06580e;
}
.sidebar_menu {
  border-bottom: 1px solid #f3f0f0;
  background: white;
  color: black;
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
}
/* .sidebar_menu:hover{
    background:white ;
    color:#06580E;
    border-left: 5px solid;
} */
.sidebar_menuu_accordian > a:focus {
  box-shadow: none;
  outline: none;
}
.sidebar_menuu_accordian > a {
  border-bottom: 1px solid #f3f0f0;
  background: white;
  color: black;
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
  text-align: left;
  width: 100%;
}
.sidebar_menu > a {
  color: #000;
}
.chairman_p:hover a {
  color: #fff;
  text-decoration: none;
}
.btn-success {
  background-color: #06580e;
  border: 1px solid #06580e;
}
.btn_success_1 {
  color: #fff;
  background-color: #06580e !important;

  border-radius: 7px;
}
.btn_success_1:hover {
  color: #fff;
  background-color: #218838 !important;
  border-color: #06580e;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838 !important;
  border-color: #06580e;
}

.padding_10 {
  padding: 10px;
}

.pdf_text {
  font-weight: 600;
  font-size: 12px;
}
.download_button {
  background-color: #06580e;
  text-decoration: none;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 90px;
  /* float: right; */
}

a.download_button:hover {
  color: black !important;
  text-decoration: none !important;
}

.card_round_img {
  width: 45px;
  border-radius: 50%;
  height: 45px;
}

/* .p_b {
    padding:7px 0px 7px 4px;
} */

.labelcolr {
  background-color: #06580e;
  text-align: center;
  padding: 10px;
  width: 46px;
  font-size: 17px;
  color: white;
  font-weight: 600;
  /* font-family: sans-serif */
}

.hr_mar {
  border: 1px solid #d8d8d8;
  margin-top: 0px;
}

.ch_m {
  padding: 15px 0px;
  font-size: 18px;
  font-weight: 600;
}

.pd_2 {
  padding-left: 2%;
}

.ol_pl {
  padding-left: 1rem;
}

.pt_2 {
  padding-top: 1rem;
}

.pt_2v {
  padding-top: 2vh;
}

.co_black {
  color: black;
}
.form-control:focus {
  color: unset;
}

.intr_mr {
  margin: 30px;
}

.fz_36 {
  font-size: 2.3rem;
}

.mb_uns {
  margin-bottom: unset;
}

.headerline {
  border-right: 1px solid #efefef;
}

.padding_un {
  padding: unset;
}

.mlr_10 {
  /* margin-left: 10px; */
  margin-right: 1px;
}

.co_greeen_imp {
  color: #06580e !important;
}
.co_news {
  color: rgb(0, 0, 0) !important;
  font-size: 14px !important;
}
.co_news {
  color: rgb(43, 56, 47);
}
.co_EAE {
  color: #a8a8a8 !important;
}
.backco_eae {
  background-color: #e9e9e9;
}
.btn_eae {
  background-color: #e9e9e9;
}
.btn_eae:hover {
  background-color: #dafdbd;
  border: 1px solid #dafdbd;
  color: black;
}
.btn_DAFDBD {
  background-color: #dafdbd;
  color: black;
}

.peb_border {
  padding: 2%;
  border: 2px solid #f3e9e9;
  border-radius: 8px;
}
.peb_btn_box {
  background-color: #e9e9e9;
  padding: 1.5%;
  border-radius: 50px;
  float: right;
}
.peb_head {
  padding: 7px;
  border-radius: 9px;
  padding-left: 20px;
}
.ml_12 {
  margin-left: -12px;
}
.mr_15 {
  margin-right: -15px;
}
.inline_flex {
  display: inline-flex;
}

/* app cs ko khtm karna k lia */
.navbar-nav {
  margin-left: unset;
}

.nav_zind {
  background-color: white;
}

/* .card-img-top {
    border-top-left-radius: unset;
    border-top-right-radius: unset;
} */

.star-rating {
  /*  border:solid 1px #ccc;*/
  display: flex;
  flex-direction: row-reverse;
  font-size: 1.5em;
  justify-content: space-around;
  padding: 0 0.2em;
  text-align: center;
  width: 4em;
}

.star-rating input {
  display: none;
}

/* .star-rating label {
    color: #ccc;
    cursor: pointer;
} */
.star-rating :checked ~ label {
  color: #f90;
}

/* .star-rating label:hover,
.star-rating label:hover ~ label {
    color: #fc0;
} */
.co_star {
  color: #fc0;
  font-size: 22px;
}

.co_unstar {
  color: #edeee9;
}

.Log_none {
  /* visibility: hidden; */
  display: none;
}

.aboutCPDh:hover {
  background-color: white !important;
  color: #2c3449;
}
.font_we {
  font-weight: 800;
}

::-webkit-input-placeholder {
  color: black !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: black;
}

::-moz-placeholder {
  color: black;
  opacity: 1;
}

:-moz-placeholder {
  color: black;
  opacity: 1;
}

/* .input::placehold {
    color: black;
    opacity: 1;
  } */
.place_white::placeholder {
  color: white !important;
  opacity: 1;
}

.backco_white {
  background-color: white;
}

.img_head {
  height: auto;
  width: 90%;
}

.h1_all_demo {
  padding: 15vh 0vh;
  text-align: center;
  color: green;
  background-color: white;
}

.navMar_left {
  margin-left: unset;
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0 )' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

/* .font_sans {
    font-family: sans-serif;
} */

.fw_sans8 {
  font-weight: 700;
}

@font-face {
  font-family: "Merriweather";
  src: url(../fonts/Merriweather/Merriweather-Regular.ttf);
  /* src: url(../fonts/Merriweather/Merriweather-Bold.ttf); */
  /* src: url(../fonts/Merriweather/Merriweather-BoldItalic.ttf); */
}

/* @font-face {
    font-family: 'Open_Sans';
    src: url(../fonts/Open_Sans/OpenSans-Regular.ttf);
    src: url(../fonts/Open_Sans/OpenSans-Bold.ttf);
    src: url(../fonts/Open_Sans/OpenSans-SemiBold.ttf);
    src: url(../fonts/Open_Sans/OpenSans-ExtraBold.ttf);
} */

/* @font-face {
    font-family: 'Open_Sans';
    src: url(../fonts/Open_Sans/OpenSans-Bold.ttf);
    }
    @font-face {
    font-family: 'Open_Sans';
    src: url(../fonts/Open_Sans/OpenSans-SemiBold.ttf);
    }
    @font-face {
    font-family: 'Open_Sans';
    src: url(../fonts/Open_Sans/OpenSans-ExtraBold.ttf);
    } */
/* @font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/Merriweather/Merriweather-Regular.ttf'); 
    src: local('Open Sans'), local('Merriweather'), */
/* url('../fonts/open-sans-v13-latin-regular.eot?#iefix') format('embedded-opentype'), 
         url('../fonts/open-sans-v13-latin-regular.woff2') format('woff2'), 
         url('../fonts/open-sans-v13-latin-regular.woff') format('woff'), 
         url('../fonts/open-sans-v13-latin-regular.ttf') format('truetype'),
         url('../fonts/open-sans-v13-latin-regular.svg#OpenSans') format('svg');  */
/* } */

.ml_logo {
  margin-left: 74%;
}

.marque_bord {
  border-bottom: 1px solid darkgreen;
  height: 75vh;
}
.dropdown-item {
  color: black !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #fff !important;
  text-decoration: none;
  background-color: #06580e;
}

.Conti_Prof {
  font-family: fantasy;

  font-size: 2.1rem;
  color: #06580e;
  text-align: center;
}

/* .cpdinfo_border {
    padding-left: 1%;
    padding-right: 7px;
    margin-top: 30px;
    border: 1px solid #EFEFEF;
    margin: 9px;
    background-color: white;
} */

.floating-div {
  /* padding-left: 1%;
    padding-right: 7px;
    margin-top: 30px;
    border: 1px solid #EFEFEF;
    margin: 9px;
    background-color: white; */
  margin-top: 10px;
  /* padding-left: 1%; */
  /* padding-right: 7px; */
  margin-top: 30px;
  border: 1px solid #efefef;
  margin: 13px;
  background-color: white;
}

.sticky {
  position: fixed;
  /* top: 10px;
        left:18px;
        margin: 0; 
        width:calc(33.33% - 25px); */
  /* padding-left: 1%;
        padding-right:7px;
        margin-top: 30px;
        border:1px solid #EFEFEF;
        margin:9px;
        background-color:white; */
  width: 20%;
  z-index: 1;
  top: 20px;
}

.abs {
  position: absolute;
  /* bottom: 10px;
        right:10px; */
  /* width:calc(33.33% - 20px); */
}

.flex-container1 > div {
  margin: 3px;
  padding: 4px;
  display: flex;
  justify-content: center;
  text-align: center;
}
.circle_handle p {
  /* margin-top: 7px; */
  margin-bottom: unset;
  font-size: 13px;
  font-weight: bold;
}
.icon_center {
  text-align: unset;
}
.display_ipad {
  display: none;
}
.display_none {
  display: none;
}
.mymarqueeid {
  height: 60vh;
}
.inner_master {
  padding-left: 5%;
  padding-right: 7%;
}
.mlr_auto {
  margin-left: auto;
  margin-right: auto;
}
.cpd_Act_btn {
  width: 162px;
  padding: 8px;
}
.hei_5v {
  height: 9vh;
}
.pebHead_mar {
  margin: 28px 0px 0px -12px;
}

.st-dtitle {
  background-color: limegreen !important;
}
.st-bg {
  background: black !important;
}
/* .st-bg-today { background:yellow !important; border-left:1px solid yellow; border-right:1px solid yellow; } */
#calendarTabs1 .st-bg-today {
  background: yellow !important;
}

.view-cap,
.view-container-border {
  background-color: limegreen !important;
}
.mv-dayname {
  background-color: limegreen !important;
}

/* CSS for responsive iframe */
/* ========================= */

/* outer wrapper: set max-width & max-height; max-height greater than padding-bottom % will be ineffective and height will = padding-bottom % of max-width */
#Iframe-Family-History-Conference {
  max-width: 600px;
  max-height: 100%;
  overflow: hidden;
}
#Iframe-Google-Calendar {
  max-height: 600px;
  max-width: 800px;
  overflow: hidden;
}

/* inner wrapper: make responsive */
.responsive-wrapper {
  position: relative;
  height: 0; /* gets height from padding-bottom */

  /* put following styles (necessary for overflow and scrolling handling on mobile devices) inline in .responsive-wrapper around iframe because not stable in CSS:
      -webkit-overflow-scrolling: touch; overflow: auto; */
}

.responsive-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  margin: 0;
  padding: 0;
  border: none;
}

/* padding-bottom = h/w as % -- sets aspect ratio */
/* YouTube video aspect ratio */
.responsive-wrapper-wxh-650x315 {
  padding-bottom: 56.25%;
}
.responsive-wrapper-wxh-600x480 {
  padding-bottom: 80%;
}
.responsive-wrapper-padding-bottom-75pct {
  padding-bottom: 75%;
}
/* ==============  calander design*/
.set-border {
  border: 5px inset #4f4f4f;
}
.set-box-shadow {
  -webkit-box-shadow: 4px 4px 14px #4f4f4f;
  -moz-box-shadow: 4px 4px 14px #4f4f4f;
  box-shadow: 4px 4px 14px #4f4f4f;
}
.set-padding {
  padding: 40px;
}
.set-margin {
  margin: 30px;
}
.center-block-horiz {
  margin-left: auto !important;
  margin-right: auto !important;
}
.ui-rtsr-selected {
  background-color: limegreen !important;
}
/* ==============  calander design*/

.counter_center {
  padding: 18%;
  background-color: #1fa84c;
  color: white;
  font-size: 20px;
  font-weight: bold;
  border-radius: 7px;
}
.counter_centerUserlogs {
  padding: 14%;
  background-color: #1fa84c;
  color: white;
  font-size: 24px;
  font-weight: bold;
  border-radius: 7px;
}
.Counter_days {
  background-color: transparent;
  color: white;
  font-weight: bold;
  margin-top: 5vh;
}
.counter_desk {
  height: 27vh;
  width: 100%;
  margin-top: 20vh;
}
.btn_counter {
  width: 140%;
  height: 50px;
  margin-top: 7vh;
  margin-left: -5%;
  margin-right: -5%;
}
.btn_counter_logs_btn {
  width: 100%;
  height: 50px;
  margin-top: 3vh;
  margin-bottom: 7vh;
}
.paper_div_border {
  border: 1px solid black;
  height: 50px;
  width: 100%;
  margin: 12vh 0px;
}
.paper_clip_sty {
  padding-top: 0vh;
  font-size: xx-large;
}
.live_web_border {
  border: 1px solid;
  border-radius: 12px;
  box-shadow: 0px 4px #dedede;
}
.live_web_border_admin {
  border: 1px solid #00000045;
  border-radius: 12px;
}
.live_web_border_0 {
  border: 1px solid #00000045;
  border-radius: 0px;
}
.btn_color_border {
  border: 1px solid #20a84c;
  color: #20a84c;
  font-weight: bold;
}
.admin_box_border {
  border-left: 1px solid #00000045;
  /* border-bottom-right-radius: 12px;
    border-top-right-radius: 12px; */
}
.text_file_box {
  background-color: #1fa84c;
  padding: 6vh 1px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.text_file_box_admin {
  /* background-color: #1FA84C; */
  padding: 6vh 1px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.image_borer {
  border-radius: 9px;
  background-color: green;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.img_upload_webi {
  height: 25vh;
  width: 21vh;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.flex-image-upload {
  display: flex;
  flex-wrap: nowrap;
}
.flex-image-upload > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex_peb {
  display: flex;
  flex-wrap: wrap;
}
.flex_peb > span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 10px 10px 0px;
  text-align: center;
}
.text_center_peb {
  text-align: center !important;
}
.ptb_4v {
  padding: 4vh 0px;
}

.backco_1F {
  background-color: #1fa84c;
}
.height_5v {
  height: 5vh;
}
.height_9v {
  height: 9vh;
}
.height_7v {
  height: 7vh;
}
.mtb_7v {
  margin: 7vh 0px;
}
.mtb_2v {
  margin: 2vh 0px;
}
.file_upload_border {
  border: 3px dashed black;
}
.file_upload_admin {
  border: 1px dashed black;
  border-radius: 5px;
  text-align: center;
}
.btn_upload_admin {
  width: 40%;
  border-radius: 5px;
}
.co_admin {
  color: #20a84c;
}
.delbutton {
  color: #a82420;
}
.co_1F {
  color: #1fa84c;
}
.live_webinar_text {
  margin: 6vh 0px 0px 1px;
  border: 1px solid;
  border-radius: 9px;
  /* height: 5vh; */
  padding: 1vh;
  text-align: center;
}

/* .dropdown-submenu {
    display: hidden;
  }
  .dropdown-submenu:hover ul {
    display: block;
  } */
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu a::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 6px;
  top: 0.8em;
}
.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}
.custom-drop-sub {
  display: none !important;
}
.dropdown-toggle:hover > .custom-drop-sub {
  display: block !important;
}
.dropdown-submenu:hover > .custom-drop-sub {
  display: block !important;
}
.accordin_bg {
  background-color: #06580e;
  padding: 6px 6px;
  font-size: 14px;
  font-weight: bold;
  border: 0px solid;
  outline: none;
  width: 100%;
  text-align: left;
  /* box-shadow: none; */
}
.accordin_bg:hover {
  background-color: white;
  color: #06580e;
}
.accordin_bg:active {
  color: unset !important;
  background-color: unset !important;
  border-color: unset !important;
  outline: none;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.accordin_bg:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.a_footer {
  color: white;
}
.a_footer:hover {
  color: white;
}
.card_gallery_img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 40vh;
}
.card_gallery_body {
  box-shadow: 0px 4px 3px 0px #6f6565fc;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.card_Subgallery_img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 40vh;
}
.btn_carousel {
  width: 145px;
  height: 45px;
  margin-top: 12px;
  background-color: #06580e !important;
}

.nav-item {
  height: 50px;
  padding-top: 2px;
}
.pill {
  height: auto !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .pl_12 {
    padding-left: unset;
  }

  .mr_23 {
    margin-right: unset;
  }

  .w_90 {
    width: unset;
    margin-left: unset;
  }

  .pd_right {
    padding-right: unset;
  }

  .card_w {
    width: 80%;
  }

  .body {
    margin: -2px;
  }
  .w_112 {
    width: 100%;
  }

  .mr_24 {
    margin-right: 3%;
    margin-left: 3%;
  }

  .vl {
    border-right: unset;
    height: unset;
  }

  .check-pl {
    padding-left: 11%;
  }

  .sub_new {
    padding: 30px 0px 0px;
  }

  .fz_2 {
    font-size: 2rem;
  }

  .our_num {
    padding-top: unset;
  }

  .none {
    display: none;
  }

  .starBoxH {
    display: none;
  }

  .nav_zind {
    background-color: white;
    z-index: 1;
  }

  .Log_none {
    /* visibility: visible; */
    display: block;
  }

  .mr_36 {
    margin-bottom: 25px;
  }

  .headerline {
    border-right: unset;
    height: 40px;
    margin-left: 6%;
  }

  /* .navMar_left {
        margin-left: 20px;
    } */

  .pd_80 {
    padding: 30px;
  }

  .ml_logo {
    margin-left: 25%;
  }

  /* .mr_unset {
        margin-right: -14px;
    } */
  .pt_4_5v {
    padding-top: 4.5vh;
  }

  .marque_left {
    padding-left: 7%;
  }

  .mlr_marq_auto {
    margin-left: auto;
    margin-right: auto;
  }
  .plr_flexcontainer {
    padding-left: 30px;
    padding-right: 30px;
  }
  .lead {
    font-size: 13px;
  }
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.2rem;
  }
  h3 {
    font-size: 1rem;
  }
  h4 {
    font-size: 0.9rem;
  }
  h5 {
    font-size: 0.8rem;
  }
  .masthead {
    height: 60vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-left: 30px;
    padding-right: 20px;
  }
  .into-p {
    line-height: 2;
    font-size: 12px;
  }
  .containe_unset {
    all: unset;
  }
  .icon_center {
    text-align: center;
  }
  .Conti_Prof {
    font-size: 1.4rem;
  }
  .pr_7 {
    padding-right: 0px;
    padding-left: unset;
  }
  .peb_pb_10 {
    padding-bottom: unset;
  }
  .pebHead_mar {
    margin: 28px 0px 0px 12px;
  }
  .peb_border {
    padding: 6% 2%;
  }
  .peb_pt_10 {
    padding-top: 10px;
  }
  .counter_center {
    padding: 3%;
  }
  .btn_counter {
    width: 75%;
    height: 50px;
    margin-left: 12%;
    margin-top: unset;
  }
  .icon_text {
    padding: 5vh 0px 5vh 2vh;
  }
  .text_file_box {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: unset;
  }
  .counter_desk {
    height: 65vh;
    width: 100%;
    margin: 5vh 0px;
  }
  .counter_centerUserlogs {
    padding: 3%;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .intr_mr {
    margin: unset;
  }

  .none {
    display: none;
  }

  .nav_zind {
    background-color: white;
    z-index: 1;
  }

  .Log_none {
    visibility: hidden;
  }

  .mlr_10 {
    margin-right: -6px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
/* @media screen (min-width:768px), (max-width:868px ) {
    .none {
        display: block;
    }

    .nav_zind {
        background-color: white;
        z-index: 1;
    }
    .mlr_10 {
        margin-right: -5px;
    }

} */
/* ,(orientation: portrait)  @media screen (min-width:768px), (width:768px ) {         */
@media only screen and (width: 768px) {
  .display_ipad {
    display: inline-flex;
  }
  .ipd_col_pad {
    padding-left: unset;
    padding-right: unset;
  }
  .display_none {
    display: block;
  }

  .none_other {
    display: none;
  }

  .lead {
    font-size: 13px;
  }

  h1 {
    font-size: 1.3rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1rem;
  }

  h4 {
    font-size: 0.9rem;
  }
  h5 {
    font-size: 0.8rem;
  }

  .Conti_Prof {
    font-size: 1.5rem;
  }

  .fz_16 {
    font-size: 0.8rem;
  }

  .marque_bord {
    height: 55vh;
  }

  .mymarqueeid {
    height: 41vh;
  }

  .masthead {
    height: 55vh;
  }

  .into-p {
    font-size: 12px;
  }

  .pt_10I {
    padding-top: 11px !important;
  }

  .mlr_10 {
    margin-right: -3px;
  }

  .inner_master {
    padding-left: 5%;
    padding-right: 7%;
  }

  .sub_new {
    padding: 50px 0px 0px;
  }

  .our_num {
    padding-top: 4vh;
  }

  .pt_100 {
    padding-top: 4vh;
  }

  .pt_5 {
    padding-top: 3vh;
  }

  .pb_5 {
    padding-bottom: 3vh;
  }
  .pt_7ip {
    padding-top: 7px;
  }
  .mlr_auto {
    margin-left: auto;
    margin-right: auto;
  }
  .headerline {
    border-right: 0px solid #efefef;
    height: 40px;
    padding-right: 56vh;
    padding-left: 6vh;
    margin-left: 2vh;
  }
  .containe_unset {
    all: unset;
  }

  .box {
    padding: 50px 15px;
  }
  .chairman_p {
    font-size: 12px;
  }
  .peb_border {
    padding: 3% 2%;
  }
  .co_green {
    color: #06580e !important;
  }
  .card2 {
    width: 80%;
    height: 18vh;
  }
  .pd_80 {
    padding: 30px;
  }
  .list_group_item_padding {
    padding: 0.75rem 0.7rem;
  }
  .card_gallery_img {
    height: 25vh;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 868px) {
  .mlr_10 {
    margin-right: -5px;
  }
  .list_group_item_padding {
    padding: 0.75rem 0.2rem;
  }
}
/* @media (min-width: 768px) {
    .navbar-expand-md {
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
    }
  } */

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .none {
    display: block;
  }

  .mlr_10 {
    margin-right: 5px;
  }
}
@media only screen and (min-width: 1024px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .none {
    display: block;
  }
}
