.back_image{
    background-image: url('../../image/exam.png');
    width: 100%;
    height: 70%;
}
.i_clock_Des{
    margin-top: 2px;
    margin-right: 8px;
    font-size: 16px;
}
.inner_text_Header{
    padding-right: 7%;
    
}
.top-container{
    margin-top:7vh;
}
 .imagecontainer{
    text-align: center;
    margin: 1em 0;
 }
 .imagebackground{
  height:50vh;
  width: 100%;
  object-fit: cover;

}
.card_image_description{
    height: 30vh;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}
.cat_list{
    list-style: none;
    display: block;
    margin-right:1%;
    margin-left:1%;
}
.csrd-text{
    margin-right:5px;
}
.web_desc_nav_tabs .web_desc_navlink{

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.web_desc_nav_tabs .web_desc_navlink.active{
    background-color: #06580e;
    color:white;
}
.web_desc_nav_tabs .web_desc_navlink:hover{
    background-color: #1FA84C;
    color:white;
}
.web_desc_nav_tabs{
    border-bottom:1px solid #06580e;
    font-size:14px;
}
.watch_video{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color:black;
    font-size:16px;
}
.web_desc_nav_tabs .watch_video.active{
    background-color: #06580e;
    color:white;
}
.watch_video:hover{
    background-color: #1FA84C;
    color:white;
}

 .mtb_10{
     margin: 10vh 0px;
 }
 .icon_text{
    padding: 5vh 0px 5vh 14vh;
    background-color: #F9F9F9;
 }
 .lineheight_2{
     line-height: 2;
 }
 .ml_3p{
     margin-left: 3%;
 }
 .Des_text{
    line-height: 1.6;
    
    text-align: justify;
 }
 .img_hei{
    width: 100%;
    height: 17vh;
    background-size: cover;
    border-radius: 10px;
 }
 .fz_18p{
     font-size: 18px;
 }
 .webinar_banner_image{
    display: flex;
    justify-content: center;
    width: 100%;
 }
 .webinar_banner_image>img{
    height: 400px;
   object-fit: cover;
    
    width: 100%;
  
   
 }
 .tab-pane{
     min-height:250px;
    padding-bottom:20px;
 }
 .btn_div{
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;
 }
 /* #contentofsite { 
    height: 2vh; 
    overflow: hidden; 
}
#showmemore:hover + #contentofsite { 
    height: auto;
    overflow: visible;
} */
#more {display: none;}
.mt_unset{
    margin-top: unset;
}
.span_fz{
    font-size: 14px;
}
.btnshowmore_less{
    background: white;
    color: #1FA84C;
    font-size: 19px;
    font-weight: 700;
    width: 130px;
    border: 0px solid;
    outline: none;
    box-shadow: none;
    height: 35px;
}

.card_desc{
    -webkit-box-shadow: 1px 1px 10px 1px rgba(196,196,196,1);
    -moz-box-shadow: 1px 1px 10px 1px rgba(196,196,196,1);
    box-shadow: 1px 1px 10px 1px rgba(196,196,196,1);
    
    border-radius:12px!important;
    
}
.s_option:hover{
    background-color: #1FA84C!important;
    border:none;
}











@media only screen and (max-width: 600px) {
    .img_hei {
        width: 48%;
        height: 19vh;
    }
    .span_fz{
        font-size: 0.75rem;
    }
    .icon_text {
        padding: 5vh 0px 5vh 8vh;
    }
    .text_center_mob{
        text-align: center;
    }

}


@media only screen and (min-width: 600px) {
   
}
/* @media screen and (min-width:768px), (max-width:850px ) {  this is true  */
@media only screen and (width:768px) {
    .icon_text {
        padding: 5vh 0px 5vh 2vh;
    }
    .inner_text_Header {
        padding-left: 8%;
        padding-right:unset;
    }
    .img_hei {
       height: 9vh;
    }
    .span_fz{
        font-size: 0.75rem;
    }
    
   
}
@media only screen and (min-width: 868px) {
   
}
@media only screen and (min-width: 992px) {
   
}
@media only screen and (min-width: 1024px) {
   
}
@media only screen and (min-width: 1200px) {
   
}