.hrrow{
    border: 1px solid black;
}
.a_prop{
    color: black;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    width: 100%;
}
.nav-tabs{
    border-bottom: unset;
}
.mtb_8v{
    margin: 3vh 0px;
    
}
.mp-8{
    margin:3vh 0px;
    padding: 2vh 0px;
}
.text_border{
    border: 1px rgb(80, 80, 80) solid;
    border-radius:5px;
}
.card_webinar {
    border-radius: 12px !important;
    margin-top: 15px !important ;
    margin-bottom:15px !important;
   
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property:transform;
    transition-property: box-shadow,transform;
    -webkit-box-shadow: -1px 1px 22px -3px rgba(156,156,156,1);
    -moz-box-shadow: -1px 1px 22px -3px rgba(156,156,156,1);
    box-shadow: -1px 1px 22px -3px rgba(156,156,156,1);
    
}
.card_webinar:hover{
    -webkit-box-shadow: 1px 1px 22px 2px rgba(97,97,97,1);
    -moz-box-shadow: 1px 1px 22px 2px rgba(97,97,97,1);
    box-shadow: 1px 1px 22px 2px rgba(97,97,97,1);
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

.card-bod{
    
   
    border-top: unset ;
    
    border-top-left-radius: unset ;
    border-top-right-radius: unset ;
   
}

.card-img-top_webinar{
    height: 200px;

    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}
.mb_unset{
    margin-bottom: unset;
}
.i_clock{
    float: left;
    margin-top: 2px;
    margin-right: 18px;
}
.price{
    float: right;
    font-size:1.3rem;
    margin-top: -1vh;
    color: #1FA84C;
}
.page-link{
    padding:0.5rem 2rem;
    color: black;
    border: unset;
}
.page-link:hover{
    color: white;
    background-color: #1FA84C;
}
.mtb_15vh{
    margin: 15vh 0px;
}
.title{
    white-space:pre;
    overflow:hidden;
    text-overflow: ellipsis;
}



/* .btnpagination {
    border: none;
    outline: none;
    cursor: pointer;
  }
  .active, .btnpagination:hover {
    color: white;
    background-color: #1FA84C;
  } */
.pagination{
    margin: 0 auto;
    padding-left:80px;
    width:500px;
    padding-bottom:20px;
}
.pagination a{
    padding-top:5px;
    padding-bottom:5px;
    padding-left:10px;
    padding-right:10px;
    margin-left:10px;
    margin-right:10px;
    border-radius:5px;
   
    color:black;

  }
.pagination a:active {
    outline:none!important;
    background-color: #1FA84C!important;
    color:white!important;
}
.pagination a:focus {
    outline:none!important;
    background-color: #1FA84C!important;
    color:white!important;
}
.pagination a:hover{
    cursor: pointer;
    color:white;
    background-color: #1FA84C;
}

a .previous-page{
   
    
    color:#1FA84C!important;
   
}
a .next-page{
   
    color:#1FA84C!important;
    
}
.loaderDiv{
    display: flex;
    height:400px;
    justify-content: center;
    align-items: center;
    width: 100%
}











@media only screen and (max-width: 600px) {
  
}
@media only screen and (min-width: 600px) {
   
}
@media only screen and (width:768px) {
    /* .card-img-top_webinar {
        height: 22vh;
    } */
   
}
@media only screen and (min-width: 868px) {
}
@media only screen and (min-width: 992px) {
}
@media only screen and (min-width: 1024px) {
}
@media only screen and (min-width: 1200px) {
}